import React from 'react';
import {
  Flex,
  Button,
  Text,
  Box,
  Image as RebassImage
} from 'rebass/styled-components';
import StyledRectangle from '../components/StyledRectangle';
import CardSubtitle from '../components/CardSubtitle';
import ReactMarkdown from 'react-markdown';
import { Link as GatsbyLink } from 'gatsby';
import { STORAGE_KEYS } from '../resources/constants';
import styled from 'styled-components';
import { shareReportService } from '../services/services';
import MarkdownLinkRenderer from "./MarkdownLinkRenderer";

const StyledText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #161f26;
  img {
    width: 100%;
  }

  a {
    color: #0a99c7;
  }
`;

const Image = styled(RebassImage)`
  max-width: none !important;
`;

const SecondarySection = ({ data_section, handleTrackCustomEvent }) => {
  const generateS3 = event => {
    const data = {
      email: 'technology@progentec.com',
      addToNewsletter: false
    };

    shareReportService(data)
      .then(response => {
        if (response.status === '200') {
          handleTrackCustomEvent(data_section.button.text);
        }
      })
      .catch(error => {
        console.log(error);
        handleTrackCustomEvent(data_section.button.text);
      });
  };

  return (
    <StyledRectangle bg="white" px={4}>
      <Flex justifyContent="center" alignItems="center" pt={5}>
        {/* <Box width={[2 / 10, 2 / 12]} mr={[2, 0]}> */}
        {/*   <Image */}
        {/*     src={data_section.icon.file.url} */}
        {/*     alt={data_section.icon.file.fileName} */}
        {/*     width="178px" */}
        {/*     height="37px" */}
        {/*   /> */}
        {/* </Box> */}
        <Box width={[9 / 10, 11 / 12]}>
          <CardSubtitle fontSize="18px">{data_section.title}</CardSubtitle>
        </Box>
      </Flex>
      <StyledText>
        <ReactMarkdown
          source={data_section.text.text}
          renderers={{ link: MarkdownLinkRenderer }}
          fontSize="14px !important"
        />
      </StyledText>
      <Flex justifyContent="center" alignItems="center">
        {data_section.button && (
          <GatsbyLink
            to={
              data_section.button.urlInternal
                ? data_section.button.urlInternal
                : `${data_section.button.url}?userid=${localStorage.getItem(
                    STORAGE_KEYS.ID
                  )}`
            }
            target={data_section.button.urlInternal ? '' : '_blank'}
          >
            <Button variant="bDarkRed" height="41px">
              {data_section.button.text}
            </Button>
          </GatsbyLink>
        )}
      </Flex>

      {/* <span> */}
      {/*   Learn more about our&nbsp; */}
      {/*   <GatsbyLink */}
      {/*     to="https://form.jotform.com/230813532730145" */}
      {/*     target="_blank" */}
      {/*   > */}
      {/*     PROACTIVE™ study */}
      {/*   </GatsbyLink> */}
      {/* </span> */}
    </StyledRectangle>
  );
};

export default SecondarySection;
